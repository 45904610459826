import React, { useCallback, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Modal, Space, Tabs, TabsProps, Typography } from 'antd';

import { titleCase } from '../../../services/formatting';

import { GlobalSearchModel } from '../models/global-search';

import { getTitle, GlobalSearchResult } from './GlobalSearchResult';
import { GlobalSearchViewDelegate } from './GlobalSearchView';

import { DocumentationApi } from '../../documentation/documentation-api';
import { KNOWN_ZOE_DOCS_URI_MAP } from '../../documentation/views/DocumentationScreen';
import { SearchResult } from '../search-api';
import styles from './GlobalSearchResults.module.css';

export function GlobalSearchResults({ model, delegate }: { model: GlobalSearchModel; delegate: GlobalSearchViewDelegate }) {
  const KNOWN_DOCS_MAP = useRef<{ [key: string]: string }>(KNOWN_ZOE_DOCS_URI_MAP);
  useEffect(() => {
    const getKnownDocsUrls = async () => {
      try {
        const api = new DocumentationApi();
        KNOWN_DOCS_MAP.current = {
          ...(await api.getAllDocumentationLinks())?.reduce(
            (acc, doc) => {
              acc[doc.id] = doc.id; //all docs have the path as the id...
              return acc;
            },
            {} as { [key: string]: string }
          ),
          ...KNOWN_ZOE_DOCS_URI_MAP, //...except for these ones
        } as { [key: string]: string };
      } catch (error) {
        console.error('Failed to fetch documentation links:', error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getKnownDocsUrls();
  }, []);
  const navigate = useNavigate();

  const { status, modalOpen, searchResults, inputValue, selectedModalTab } = model;

  const handleSearchButton = useCallback(() => {
    void delegate.onGlobalSearch();
  }, [delegate]);

  const handleCancel = useCallback(() => {
    delegate.closeModal();
  }, [delegate]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      void delegate.onGlobalSearch();
    },
    [delegate]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      delegate.onSearchTermChange(e.target.value);
    },
    [delegate]
  );

  const handleClickItem = useCallback(
    (key: string) => {
      delegate.onSearchTermChange('');
      delegate.closeModal();

      const url = key.substring(key.indexOf('|') + 1);
      if (url !== '') {
        const external = url.startsWith('http');
        if (!external) {
          navigate(url);
          return;
        }
        window.location.href = url;
      } else {
        console.error('Url not found for key:', key);
      }
    },
    [navigate, delegate]
  );

  const handleTabChange = useCallback(
    (tabId: string) => {
      delegate.onSelectTab(tabId);
    },
    [delegate]
  );

  if (!modalOpen) {
    return null;
  }

  const entries = Object.entries(searchResults);
  const tabPanes: TabsProps['items'] = entries.map(([key, value]) => {
    const searchResultsForType: (SearchResult | undefined)[] = value.searchResults || [];
    return {
      key,
      label: (
        <span>
          {titleCase(getTitle(key))}
          {value.status === 'loaded' && (
            <span className={searchResultsForType.length > 0 ? styles.boldLabel : ''}>{' (' + searchResultsForType.length + ')'}</span>
          )}
        </span>
      ),
      icon: value.status === 'loading' ? <LoadingOutlined /> : <SearchOutlined />,
      children: <GlobalSearchResult key={key} result={value} onClickItem={handleClickItem} knownDocsMap={KNOWN_DOCS_MAP.current} />,
    };
  });

  return (
    <Modal
      cancelText={'Close'}
      okButtonProps={{ style: { display: 'none' } }}
      open
      onCancel={handleCancel}
      onOk={handleCancel}
      maskClosable={false}
      width={'75%'}
    >
      <Space direction={'vertical'} className={'w-full'}>
        <div className={styles.inputContainer}>
          <label>Search:</label>
          &nbsp;&nbsp;&nbsp;
          <Input
            className={styles.inputTextbox}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={'Search...'}
            disabled={status === 'loading'}
            addonAfter={<SearchOutlined onClick={handleSearchButton} />}
          />
        </div>
        <div className={`${styles.cardContainer} w-full`}>
          <Tabs
            tabBarExtraContent={{
              left: <Typography.Title level={5}>Search Results</Typography.Title>,
            }}
            tabPosition={'left'}
            className={'w-full'}
            type={'line'}
            items={tabPanes}
            onChange={handleTabChange}
            activeKey={selectedModalTab}
            centered
          />
        </div>
      </Space>
    </Modal>
  );
}
