/* eslint-disable no-case-declarations */
import { Menu, MenuProps, Tooltip } from 'antd';
/* do not change this - it has to be /es/ */
import { SiderContext } from 'antd/es/layout/Sider';

import { getInstanceUrl } from '../../shared/instance-mapping';

import { ProviderSearchResults } from '../models/global-search';

import {
  AppstoreOutlined,
  BookOutlined,
  CloudServerOutlined,
  DashboardOutlined,
  DesktopOutlined,
  FolderOpenOutlined,
  PlayCircleOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import MenuItem from 'antd/es/menu/MenuItem';
import { useCallback } from 'react';
import { SearchResult } from '../search-api';

import { searchableMenuItemLabelMap } from '../../../side-nav/SideNav';
import { API_CLIENT_USER_GUIDE } from '../../documentation/views/DocumentationScreen';
import styles from './GlobalSearchResults.module.css';

type MenuItem = Required<MenuProps>['items'][number];

/* antd being too clever for itself - assumes anything created under a Sider will depend on the Sider's context */
const fakeSiderProps = {
  siderCollapsed: false,
};

export function getIcon(type: string) {
  switch (type) {
    case 'instances':
      return <DesktopOutlined />;
    case 'resources':
      return <CloudServerOutlined />;
    case 'fx':
      return <FolderOpenOutlined />;
    case 'dashboards':
      return <DashboardOutlined />;
    case 'applications':
      return <AppstoreOutlined />;
    case 'tasks':
      return <ShoppingOutlined />;
    case 'documentation':
      return <BookOutlined />;
    case 'springboard-file':
      return <PlayCircleOutlined />;
    default:
      return null;
  }
}

export function getTitle(type: string) {
  const mappedType = type.toUpperCase();
  if (mappedType === 'TASKS') return searchableMenuItemLabelMap['JOBS'];
  return searchableMenuItemLabelMap[mappedType] || type;
}

interface HighlightTextSafeProps {
  text: string;
}

// instead of setDangerouslyInnerHTML (matches in highlights are already wrapped in <em> tags)
function HighlightTextSafe({ text }: HighlightTextSafeProps) {
  const parts = text.split(/<em>(.*?)<\/em>/g);
  return <span>{parts.map((part, index) => (index % 2 === 1 ? <strong key={index}>{part}</strong> : part))}</span>;
}

export function GlobalSearchResult({
  result,
  onClickItem,
  knownDocsMap,
}: {
  result: ProviderSearchResults;
  onClickItem: (key: string) => void;
  knownDocsMap: { [key: string]: string };
}) {
  function renderItem(type: string, item: SearchResult): MenuItem {
    let href: string;

    switch (type) {
      case 'instances':
        href = getInstanceUrl(item.id);
        break;
      case 'resources':
        href = `/resources#resource-${item.id}`;
        break;
      case 'dashboards':
        href = `/dashboard/${item.id}`;
        break;
      case 'tasks':
        href = `/jobs/${item.id}`;
        break;
      case 'applications':
        href = `/applications/${item.id}`;
        break;
      case 'fx':
        href = item.id;
        break;
      case 'springboard-file':
        href = `/springboard-browser/#${item.id}`;
        break;
      case 'documentation':
        const filePath = item.metadata?.filePath.toString() as string;
        const maybeDocId = filePath.substring(filePath.indexOf('/') + 1, filePath.indexOf('/', filePath.indexOf('/') + 1));
        const isDocInZoe = (maybeDocId && Object.values(knownDocsMap).some((knownDocId) => knownDocId.includes(maybeDocId))) || false;

        if (item?.metadata?.sourceType === 'gitlab' || item?.metadata?.sourceType === 'github') {
          href = item.metadata?.webUrl as string;
        } else if (!isDocInZoe) {
          href = '#';
        } else {
          href =
            '/documentation' +
            (filePath.includes(knownDocsMap[API_CLIENT_USER_GUIDE])
              ? `/${API_CLIENT_USER_GUIDE}/${item.metadata?.fileName}`
              : `${item.metadata?.filePath}`);
        }
        break;
      default:
        href = '';
        break;
    }

    function renderFilePath(result: SearchResult): string {
      if (item?.metadata?.sourceType === 'gitlab' || item?.metadata?.sourceType === 'github') {
        return typeof result.metadata?.webUrl === 'string' ? result.metadata.webUrl : '';
      } else {
        return typeof result.metadata?.filePath === 'string' ? result.metadata.filePath.split('/').slice(0, -1).join('/') : '';
      }
    }

    return {
      key: `${type}|${href}`,
      icon: getIcon(type),
      className: styles.searchResultListItem,
      label: (
        <div>
          <Tooltip
            title={
              <>
                Go to {getTitle(type)} {type === 'documentation' && <span className={styles.tooltipUrl}>{href}</span>}
              </>
            }
          >
            <div>
              <div className={styles.searchResultListItemHeader}>
                <div className={styles.searchResultListItemTitleAndType}>
                  <span className={styles.searchResultListItemTitle}>{item.title}</span>
                  <span className={styles.searchResultListItemType}>
                    {item.contentType ? item.contentType.substring(0, item.contentType.indexOf('; charset')) : ''}
                  </span>
                </div>
                <p className={styles.searchResultListItemPath}>{renderFilePath(item)}</p>
              </div>
              {/*match(es) found in this file:*/}
              {item.highlights &&
                item.highlights.map((highlight, index) => (
                  <p key={index} className={styles.highlight}>
                    {`(${index + 1}) `}
                    <HighlightTextSafe text={highlight} />
                  </p>
                ))}{' '}
            </div>
          </Tooltip>
        </div>
      ),
    };
  }

  const menuItems: MenuItem[] = (result.searchResults || []).map((searchResult) => {
    return renderItem(result.label, searchResult);
  });

  const handleItemClick = useCallback(
    ({ key }: { key: string }) => {
      onClickItem?.(key);
    },
    [onClickItem]
  );

  return (
    <div className={'result-container'}>
      <SiderContext.Provider value={fakeSiderProps}>
        <Menu onClick={handleItemClick} disabled={result.status !== 'loaded'} mode={'vertical'} items={menuItems} />
      </SiderContext.Provider>
    </div>
  );
}
