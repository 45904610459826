import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import HistoryContextProviderWrapper from '../midas/context/HistoryContextProviderWrapper';

import QueryContextProviderWrapper from '../midas/context/QueryContextProviderWrapper';
import SettingsContextProviderWrapper from '../midas/context/SettingsContextProviderWrapper';
import { DashboardDetailParentController } from '../pages/dashboards/dashboard-detail-controller';
import { DashboardList } from '../pages/dashboards/models/dashboard-list';
import { DashboardNavDelegate } from '../pages/dashboards/views/DashboardNav';
import { DocumentationModel } from '../pages/documentation/models/documentation-list';
import { InstanceListModel } from '../pages/instances/models/instance-list';
import { InstanceListDelegate } from '../pages/instances/views/InstanceList';
import { JobsList } from '../pages/jobs/models/jobs-list';
import { JobDetailsDelegate } from '../pages/jobs/views/JobDetails';
import DataLakeStatusMonitor from '../pages/monitoring/views/DataLakeStatusMonitor';
import { ServicesListModel } from '../pages/services/models/services-list';
import { ServiceDetailsDelegate } from '../pages/services/views/ServiceDetails';
import { FLAG_ENABLE_SPRINGBOARD_BROWSER, FLAG_ENABLE_WB_INSIDE_ZOE, UserInfoModel } from '../pages/user-info/models/user-info';
import { NotificationsListModel } from '../side-nav/controls/notifications/models/notifications-list';
import { NotificationListControlDelegate } from '../side-nav/controls/notifications/views/NotificationsListControl';
import {
  BELLPORT_MESSAGE_MAPPING_KEY,
  DATA_LAKE_MONITOR_KEY,
  FEEDS_MONITOR_KEY,
  INSTANCES_ITEM_KEY,
  RELEASE_HISTORY_KEY,
  STATUS_DASHBOARD_KEY,
} from '../side-nav/SideNav';
import routes, { MyRoute } from '../utils/routes';
import { suspendedLazyComponent } from './views/SuspendedLazyComponent';

export function SettingsAndQueryContextProvidersWrapper() {
  return (
    <SettingsContextProviderWrapper>
      <QueryContextProviderWrapper>
        <Outlet />
      </QueryContextProviderWrapper>
    </SettingsContextProviderWrapper>
  );
}

export interface SideNavRouteProps {
  dashboardModel: DashboardList;
  dashboardDelegate: DashboardNavDelegate & DashboardDetailParentController;
  jobsModel: JobsList;
  jobsDelegate: JobDetailsDelegate;
  servicesModel: ServicesListModel;
  servicesDelegate: ServiceDetailsDelegate;
  instancesModel?: InstanceListModel;
  instancesDelegate?: InstanceListDelegate;
  notificationsModel: NotificationsListModel;
  notificationsDelegate: NotificationListControlDelegate | undefined;
  documentationModel: DocumentationModel;
  userInfoModel: UserInfoModel;
}

const Dashboards = suspendedLazyComponent(React.lazy(() => import('../pages/dashboards/views/Dashboards')));
const WelcomeScreen = suspendedLazyComponent(React.lazy(() => import('../midas/pages/welcome/WelcomeScreen')));
const DashboardDetailScreen = suspendedLazyComponent(React.lazy(() => import('../pages/dashboards/views/DashboardDetailScreen')));
const PublishedManifestsTabs = suspendedLazyComponent(React.lazy(() => import('../pages/services/views/PublishedManifestsTabs')));
const ServiceDetails = suspendedLazyComponent(React.lazy(() => import('../pages/services/views/ServiceDetails')));
const JobDetails = suspendedLazyComponent(React.lazy(() => import('../pages/jobs/views/JobDetails')));
const WorkbenchResources = suspendedLazyComponent(React.lazy(() => import('../pages/resources/views/WorkbenchResources')));
const Medusa = suspendedLazyComponent(React.lazy(() => import('../pages/medusa/views/Medusa')));
const InstanceTabsBase = suspendedLazyComponent(React.lazy(() => import('../pages/instances/views/InstanceTabsBase')));
const InstanceTabs = suspendedLazyComponent(React.lazy(() => import('../pages/instances/views/InstanceTabs')));
const FileExchange = suspendedLazyComponent(React.lazy(() => import('../pages/file-exchange/views/FileExchange')));
const DpActivityPage = suspendedLazyComponent(React.lazy(() => import('../midas/pages/dpActivity/DpActivityPage')));
const DataQueryWorkflowPageWrapper = suspendedLazyComponent(React.lazy(async () => import('../midas/pages/DataQueryWorkflowPageWrapper')));
const WorkflowPage = suspendedLazyComponent(React.lazy(() => import('../midas/pages/workflow/WorkflowPageContent')));
const UsageScreen = suspendedLazyComponent(React.lazy(() => import('../pages/usage/views/UsageScreen')));
const MarkdownDocumentation = suspendedLazyComponent(React.lazy(() => import('../pages/documentation/views/MarkdownDocumentation')));
const DocumentationScreen = suspendedLazyComponent(React.lazy(() => import('../pages/documentation/views/DocumentationScreen')));
const ReleaseHistoryScreen = suspendedLazyComponent(React.lazy(() => import('../pages/release-history/views/ReleaseHistoryScreen')));
const MappingsScreen = suspendedLazyComponent(React.lazy(() => import('../pages/bellport-message-mapping/views/MappingsScreen')));
const NotificationsScreen = suspendedLazyComponent(React.lazy(() => import('../pages/notifications/views/NotificationsScreen')));
const RawFeedData = suspendedLazyComponent(React.lazy(() => import('../pages/raw-feed-data/views/RawFeedData')));
const FeedsMonitor = suspendedLazyComponent(React.lazy(() => import('../pages/monitoring/views/FeedsMonitor')));
const StatusDashboard = suspendedLazyComponent(React.lazy(() => import('../pages/monitoring/views/StatusDashboard')));
const CancelUserResourcesDeletion = suspendedLazyComponent(React.lazy(() => import('../pages/users/views/CancelUserResourcesDeletion')));
const AppMonitorPage = suspendedLazyComponent(React.lazy(() => import('../pages/app-monitor/views/AppMonitorPage')));
const SpringboardBrowserPage = suspendedLazyComponent(React.lazy(() => import('../pages/springboard-browser/views/SpringboardBrowser')));

export function sideNavRoutes({
  dashboardModel,
  dashboardDelegate,
  jobsModel,
  jobsDelegate,
  servicesModel,
  servicesDelegate,
  instancesModel,
  instancesDelegate,
  notificationsModel,
  notificationsDelegate,
  documentationModel,
  userInfoModel,
}: SideNavRouteProps) {
  const isMidasPeer = window.CONFIG.isMidasPeer;
  const { enableStatusDashboard, enableDataVisualization, useUserDataLake } = window.CONFIG.featureFlags;

  // Note that FLAG_ENABLE_WB_INSIDE_ZOE and FLAG_ENABLE_DATA_LAKE_MONITOR are MACE user flags (getMaceUserFlags())
  const wbInsideZoe = userInfoModel.featureFlags.includes(FLAG_ENABLE_WB_INSIDE_ZOE);
  const springboardBrowser = userInfoModel.featureFlags.includes(FLAG_ENABLE_SPRINGBOARD_BROWSER);

  return (
    <>
      {/* Index route */}
      {isMidasPeer ? (
        <Route path="/" element={<WelcomeScreen />} />
      ) : (
        <Route path="/" element={<Dashboards model={dashboardModel} delegate={dashboardDelegate} />} />
      )}

      {/* Dashboards routes */}
      <Route
        path={`${routes.Dashboard}/:dashboardId`}
        element={
          <DashboardDetailScreen dashboardModel={dashboardModel} dashboardController={dashboardDelegate} userInfoModel={userInfoModel} />
        }
      />
      <Route path={routes.Dashboard} element={<Dashboards model={dashboardModel} delegate={dashboardDelegate} />} />

      {/* Applications & Jobs & Resources routes */}
      <Route path={routes.PublishedManifests} element={<PublishedManifestsTabs servicesModel={servicesModel} jobsModel={jobsModel} />} />
      <Route
        path={`${routes.Applications}/:applicationId`}
        element={<ServiceDetails model={servicesModel} delegate={servicesDelegate} userInfoModel={userInfoModel} />}
      />
      <Route path={`${routes.Jobs}/:jobId`} element={<JobDetails model={jobsModel} delegate={jobsDelegate} />} />
      <Route path={routes.Resources} element={<WorkbenchResources />} />
      {enableDataVisualization && <Route path={routes.DataVisualisation} element={<Medusa />} />}

      {/* Instances routes */}
      {wbInsideZoe && instancesDelegate && instancesModel && (
        <Route
          path={routes.Instances}
          element={
            <InstanceTabsBase
              instancesModel={instancesModel}
              instancesDelegate={instancesDelegate}
              servicesModel={servicesModel}
              userInfoModel={userInfoModel}
            />
          }
        />
      )}
      {!wbInsideZoe && (
        <Route path={routes.Instances} element={<InstanceTabs servicesModel={servicesModel} userInfoModel={userInfoModel} />} />
      )}

      {/* My backward compatibility */}
      <Route path={MyRoute.Instances} element={<InstanceTabs servicesModel={servicesModel} userInfoModel={userInfoModel} />} />
      {isMidasPeer && (
        // alias for old docs links
        <Route path={routes.Peer} element={<Navigate to={INSTANCES_ITEM_KEY} />} />
      )}
      <Route path={`${routes.Instances}/:instanceId`} element={null} />

      {/* File explorer routes */}
      {!isMidasPeer && <Route path={`${routes.FileExplorer}/:providerName?/:folderId?`} element={<FileExchange />} />}
      {!isMidasPeer && <Route path={`${routes.FileExplorer}/:providerName?`} element={<FileExchange />} />}
      {!isMidasPeer && <Route path={`${routes.FileExplorer}`} element={<FileExchange />} />}
      {!isMidasPeer && <Route path={`${routes.FileExplorer}/*`} element={<FileExchange />} />}

      {!isMidasPeer && <Route path={`${MyRoute.FileExplorer}/:providerName?/:folderId?`} element={<FileExchange />} />}

      {/* Workflows & Data routes */}
      {!isMidasPeer && useUserDataLake && (
        <>
          <Route element={<SettingsAndQueryContextProvidersWrapper />}>
            <Route
              path={routes.DpState}
              element={
                <HistoryContextProviderWrapper>
                  <DpActivityPage userInfoModel={userInfoModel} />
                </HistoryContextProviderWrapper>
              }
            />
          </Route>
        </>
      )}
      {isMidasPeer && (
        <>
          <Route element={<SettingsAndQueryContextProvidersWrapper />}>
            <Route path={routes.DpState} element={<DpActivityPage userInfoModel={userInfoModel} />} />
            <Route path={routes.Data} element={<DataQueryWorkflowPageWrapper isWorkflowsPage={false} />} />
            <Route path={`${routes.Data}/:data`} element={<DataQueryWorkflowPageWrapper isWorkflowsPage={false} />} />
            <Route path={`${routes.Workflow}`} element={<DataQueryWorkflowPageWrapper isWorkflowsPage={true} />} />
            <Route path={`${routes.Workflow}/:workflowId`} element={<DataQueryWorkflowPageWrapper isWorkflowsPage={true} />} />
            <Route path={routes.Workflow} element={<WorkflowPage />} />
          </Route>
        </>
      )}

      {/* Usage routes */}
      <Route path={`${routes.Usage}/*`} element={<UsageScreen />} />

      {/* Raw Feed Data routes */}
      <Route path={`${routes.RawFeedData}`} element={<RawFeedData />} />

      {/* Documentation routes */}
      <Route
        path={`${routes.Documentation}/midas-landing-page/*`}
        element={<MarkdownDocumentation docType="midas-landing-page" model={documentationModel} />}
      ></Route>
      <Route
        path={`${routes.Documentation}/midas-user-guide/*`}
        element={<MarkdownDocumentation docType="midas-user-guide" model={documentationModel} />}
      ></Route>
      <Route
        path={`${routes.Documentation}/thw-user-guide/*`}
        element={<MarkdownDocumentation docType="thw-user-guide" model={documentationModel} />}
      ></Route>
      <Route path={`${routes.Documentation}/:documentationId`} element={<DocumentationScreen model={documentationModel} />} />
      <Route path="/documentation/:documentationId/:docPage" element={<DocumentationScreen model={documentationModel} />} />
      <Route path={`${routes.Documentation}/${RELEASE_HISTORY_KEY}`} element={<ReleaseHistoryScreen />} />
      <Route path={`${routes.Documentation}/${BELLPORT_MESSAGE_MAPPING_KEY}`} element={<MappingsScreen />} />

      {/* Notification routes */}
      <Route path={routes.Notifications} element={<NotificationsScreen model={notificationsModel} delegate={notificationsDelegate} />} />

      {/* Monitoring routes - MIDAS */}
      {isMidasPeer && (
        <>
          <Route path={`${routes.Monitoring}/${FEEDS_MONITOR_KEY}`} element={<FeedsMonitor />} />
          <Route path={`${routes.Monitoring}/${DATA_LAKE_MONITOR_KEY}`} element={<DataLakeStatusMonitor />} />
          {enableStatusDashboard && <Route path={`${routes.Monitoring}/${STATUS_DASHBOARD_KEY}`} element={<StatusDashboard />} />}
        </>
      )}

      {/* User deletion routes */}
      <Route path={`${routes.CancelUserResourcesDeletion}/:userId`} element={<CancelUserResourcesDeletion />} />

      {/* App Monitor route */}
      <Route path={`${routes.AppMonitor}`} element={<AppMonitorPage />} />

      {springboardBrowser && <Route path={routes.SpringboardBrowser} element={<SpringboardBrowserPage />} />}
    </>
  );
}
