import { ErrorNotification } from '../../../services/error-notification';
import { ResultsFromSource, SearchApi, SearchResult } from '../search-api';

export type GlobalSearchStatus = 'idle' | 'loading' | 'loaded' | 'error';

export interface ProviderSearchResults extends ResultsFromSource {
  status: GlobalSearchStatus;
}

export interface GlobalSearchModel {
  /**
   * The value of the input box,.
   */
  inputValue: string;

  /**
   * The value we've searched for.
   */
  searchTerm: string;

  /**
   * Search results. These are grouped into the source of the result e.g. Applications, Jobs, Instances etc.
   */
  searchResults: Record<string, ProviderSearchResults>;

  /**
   * Whether or not the results modal is open.
   */
  modalOpen: boolean;

  /**
   * The current status of the search process.
   */
  status: GlobalSearchStatus;

  /**
   * A list of all available search providers.
   */
  defaultSearchProviders: string[];

  /**
   * Selected tab name in results dialog.
   */
  selectedModalTab: string;

  /**
   * Whether or not the sidebar is collapsed. Not sure this should be in the model.
   */
  sidebarCollapsed: boolean;
}

export function pack(
  model: GlobalSearchModel,
  providerName: string,
  results: Error | SearchResult[] | undefined,
  firstTabWithSomeResult?: string
): GlobalSearchModel {
  const error = results instanceof Error;

  const output = {
    ...model,
    searchResults: {
      ...model.searchResults,
      [providerName]: {
        label: providerName,
        status: error ? 'error' : 'loaded',
        searchResults: !error ? results : [],
      } as ProviderSearchResults,
    },
  };

  if (firstTabWithSomeResult) {
    output.selectedModalTab = firstTabWithSomeResult;
  }

  return output;
}

export function initialGlobalSearchResults(sidebarCollapsed: boolean): GlobalSearchModel {
  return {
    inputValue: '',
    searchTerm: '',
    searchResults: {},
    modalOpen: false,
    status: 'idle',
    defaultSearchProviders: window.CONFIG.searchProviders,
    selectedModalTab: window.CONFIG.searchProviders[0],
    sidebarCollapsed: sidebarCollapsed,
  };
}

export function createDefaultResults(providerNames: string[], status: string): Record<string, ProviderSearchResults> {
  return providerNames.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: {
        status,
        label: cur,
        searchResults: [],
      } as ProviderSearchResults,
    };
  }, {});
}

export async function searchGlobal(
  api: SearchApi,
  getModel: () => GlobalSearchModel,
  update: (model: GlobalSearchModel) => void
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  if (!model) {
    return;
  }

  const searchTerm = model.inputValue?.trim();
  if (!searchTerm) {
    update({
      ...model,
      inputValue: '',
      searchTerm: '',
      modalOpen: true,
      searchResults: createDefaultResults(model.defaultSearchProviders, 'ready'),
      status: 'idle',
    });
    return;
  }

  update({
    ...getModel(),
    searchTerm,
    status: 'loading',
    modalOpen: true,
    searchResults: createDefaultResults(model.defaultSearchProviders, 'loading'),
  });

  // request to all search providers
  const resultsWithProviders = await Promise.all(
    model.defaultSearchProviders.map(async (providerName) => {
      try {
        const results = await api.getOptionsForTerm(providerName, searchTerm);
        return { providerName, results: results?.searchResults || [] };
      } catch (err) {
        return { providerName, results: [], error: err as Error };
      }
    })
  );

  const firstTabWithSomeResult = resultsWithProviders.find((provider) => provider && provider.results.length > 0)?.providerName;
  resultsWithProviders.forEach(({ providerName, results, error }) => {
    if (error) {
      update(pack(getModel(), providerName, error));
    } else {
      update(pack(getModel(), providerName, results, firstTabWithSomeResult));
    }
  });

  update({ ...getModel(), status: 'loaded' });
}

export function clearSearch(getModel: () => GlobalSearchModel, update: (model: GlobalSearchModel) => void) {
  const model = getModel();
  update({ ...model, inputValue: '', searchTerm: '', searchResults: {}, status: 'idle' });
}

export function setInputValue(getModel: () => GlobalSearchModel, update: (model: GlobalSearchModel) => void, inputValue: string) {
  const model = getModel();
  update({ ...model, inputValue });
}

export function closeModal(getModel: () => GlobalSearchModel, update: (model: GlobalSearchModel) => void) {
  const model = getModel();
  update({ ...model, modalOpen: false, selectedModalTab: window.CONFIG.searchProviders[0] });
}
